import styled from 'styled-components'

export const Title = styled.h1`
  font-size: calc(2rem + 1.5vw);
  word-break: break-word;

  & > span {
    font-size: 40%;

    color: #00000070;
  }

  @media screen and (max-width: 500px) {
    font-size: calc(2rem + 1.5vw);
    line-height: 2.8rem;
    & > span {
      font-size: 60% !important;
      line-height: 2rem;
    }
  }
`
export const Heading = styled.h2`
  font-size: calc(1.5rem + 1.5vw);

  word-break: break-word;
  & > span {
    font-size: 40%;
    color: #00000070;
  }
  @media screen and (max-width: 500px) {
    font-size: calc(1.5rem + 1.5vw);
    & > span {
      font-size: 60% !important;
      line-height: 2.6rem;
    }
    & {
      text-align: left;
    }
  }
`

export const Intro = styled.p`
  width: 100%;
  max-width: ${props => props.width || '100%'};
  font-family: 'Roboto slab', serif;
  font-weight: 300;
  font-size: 1.5rem;
  color: ${props => (props.subtle ? '#00000070' : '#000000')};
  text-align: ${props => (props.center ? 'center' : 'inherit')};
  margin: ${props => (props.center ? '0 auto' : 'inherit')};
  @media screen and (max-width: 500px) {
    text-align: left;
    font-size: 0.8rem;
  }
`
