import { Container } from '../components/styled/layout'
import { Link } from 'gatsby'
import LogoSVG from '../assets/creandia-logo.svg'
import { MenuConsumer } from '../components/context/Menu.context'
import MenuIcon from '../assets/icons/baseline-menu-24px.svg'
import PlusIcon from '../assets/icons/baseline-add-24px.svg'
import React from 'react'
import styled from 'styled-components'

const HeaderSection = styled.div`
  padding: 20px 0px;
`
const NavLink = styled(Link)`
  text-decoration: none;
  padding: 10px 15px;
  color: #000;
  &:hover {
    color: #00000060;
  }
`

const HeaderContainer = styled(Container)`
  max-width: 1320px;
  width: 85%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: 1023px) {
    & .noMobile {
      display: none;
    }
  }
`

const Logo = styled(LogoSVG)`
  width: 54px;
`

const NavLinkCTA = styled(NavLink)`
  background: #00000010;
  padding: 5px 15px;
  border-radius: 99px;
  display: flex;
  align-items: center;
  margin-right: 0px;
`

const Header = ({ siteTitle }) => (
  <HeaderSection>
    <HeaderContainer>
      <div>
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <nav style={{ flex: '1', marginLeft: '5%' }}>
        <NavLink className="noMobile" to="/uppdrag">
          Uppdrag
        </NavLink>
        <NavLink className="noMobile" to="/event">
          Event
        </NavLink>
        <NavLink className="noMobile" to="/kommunikation">
          Kommunikation
        </NavLink>
        {/*<NavLink className="noMobile" to="/om-creandia">
          Om Creandia
</NavLink>*/}
      </nav>
      <div>
        <NavLinkCTA
          style={{ fontSize: '80%', fontWeight: '500', marginRight: '15px' }}
          to="/kontakt"
        >
          <PlusIcon style={{ marginRight: '5px', cursor: 'pointer' }} />{' '}
          Kontakta oss
        </NavLinkCTA>
        <MenuConsumer>
          {value => (
            <MenuIcon
              style={{ cursor: 'pointer' }}
              onClick={() => value.toggleMenu()}
            />
          )}
        </MenuConsumer>
      </div>
    </HeaderContainer>
  </HeaderSection>
)

export default Header
