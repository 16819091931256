import styled from 'styled-components'

export const TopContainer = styled.div`
  max-width: 1320px;
  width: 85%;
  margin: 0px auto;
`
export const Container = styled.div`
  max-width: ${props => props.width || '1320px'};
  width: 85%;
  margin: 0px auto;
  @media screen and (max-width: 500px) {
    width: ${props => (props.mobCover ? '100%' : '85%')};
  }
`
export const Section = styled.div`
  padding: ${props => props.padding || '5vmax 0px'};

  & h1 > span,
  h2 > span,
  h3 > span {
    color: #00000060;
    font-size: 80%;
    display: block;
    margin-top: 10px;
  }
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
export const Grid = styled.div`
  display: grid;
  grid-template-columns: ${props => props.columns || '1fr'};
  grid-gap: ${props => (props.nogap ? '0px' : '4vmax')};
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`

export const Column = styled.div`
  width: ${props => props.width || '100%'};
  max-width: ${props => props.maxWidth || '100%'};
  display: ${props => (props.flex ? 'flex' : 'inherit')};
  flex-direction: ${props => (props.col ? 'column' : 'row')};
  justify-content: ${props => props.justify || 'inherit'};
  align-items: ${props => props.align || 'initial'};
  background: ${props => props.bg || 'inherit'};
  padding: ${props => props.space || '0px'};
  @media screen and (max-width: 1023px) {
    & {
      flex-direction: column;
    }
  }
`
