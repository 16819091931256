import { Column, Container, Grid, Section } from '../components/styled/layout'

import { BigBtn } from '../components/styled/ui'
import { Heading } from '../components/styled/typography'
import { Link } from 'gatsby'
import { Location } from '@reach/router'
import React from 'react'
import styled from 'styled-components'

const FooterInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
  @media screen and (max-width: 1023px) {
    & {
      flex-direction: column;
      text-align: center;
    }
  }
`

const Footer = props => {
  return (
    <Location>
      {({ location }) => {
        return (
          <Container>
            {location.pathname !== '/kontakt' ? (
              <Section
                style={{
                  borderTop: '1px solid #d8d8d8',
                  borderBottom: '1px solid #d8d8d8',
                }}
              >
                <Grid columns="2fr 1fr">
                  <Column>
                    <Heading>
                      Vi skapar upplevelser. <span>Låt oss skapa din.</span>
                    </Heading>
                  </Column>
                  <Column flex align="center" justify="flex-end">
                    <BigBtn to="/kontakt">Kontakta oss</BigBtn>
                  </Column>
                </Grid>
              </Section>
            ) : null}

            <Section padding="15px 0px">
              <FooterInfo>
                <div>
                  <strong>Creandia AB</strong> | Bytaregatan 4d, 222 22 Lund |
                  info@creandia.com | (073) 969 36 36
                </div>
                <div
                  style={{
                    color: '#666',
                  }}
                >
                  Skapad med{' '}
                  <span role="img" aria-label="Heart Emoji">
                    ❤️
                  </span>
                  av <Link to="/">Creandia</Link>
                </div>
              </FooterInfo>
            </Section>
          </Container>
        )
      }}
    </Location>
  )
}

export default Footer
