import React, { Component } from 'react'

import CloseIcon from '../../assets/icons/baseline-close-24px.svg'
import { Container } from '../styled/layout'
import { Line } from '../styled/ui'
import { Link } from 'gatsby'
import LogoSVG from '../../assets/creandia-logo.svg'
import { MenuConsumer } from '../context/Menu.context'
import posed from 'react-pose'
import styled from 'styled-components'

const MobileMenuContainer = styled(
  posed.div({
    closed: { x: '100vw', delay: 0, delayChildren: 0, staggerChildren: 0 },
    open: {
      x: '0',
      transition: {
        type: 'tween',
      },
      delayChildren: 200,
      staggerChildren: 50,
    },
  })
)`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  background: #ffffff;
  width: 100vw;
  height: 100%;
  padding: 20px 0px;
  overflow-y: scroll;
`

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 5vmax;
`

const MobNavLink = styled(Link)`
  font-size: 2rem;
  text-decoration: none;
  color: #00000070;
  font-family: 'Roboto slab', serif;
  font-weight: 400;
  &.active {
    color: #000000;
    position: relative;
  }
  & > span {
    position: relative;
  }
  &.active:after {
    content: '\0a0';
    background: black;
    height: 2px;
    display: inline-block;
    width: 50px;
    right: -80px;
    position: absolute;
    top: calc(50% + 2px);
  }
  & small {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
  }
`

const LinkContainer = styled(
  posed.span({
    open: { y: 0, opacity: 1 },
    closed: { y: '20%', opacity: 0 },
  })
)`
  & > div.social-links {
    display: flex;
    flex-direction: column;
  }
  & > div.social-links a {
    color: black;
  }
`
const LogoContainer = posed.div({
  open: { opacity: 1 },
  closed: { opacity: 0 },
})
const Logo = styled(LogoSVG)`
  width: 54px;
`
const MobNavHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

class MobileMenu extends Component {
  state = {}
  render() {
    return (
      <MenuConsumer>
        {value => (
          <MobileMenuContainer pose={value.menuOpen ? 'open' : 'closed'}>
            <Container>
              <MobNavHeader>
                <LogoContainer>
                  <Link to="/">
                    <Logo />
                  </Link>
                </LogoContainer>

                <LinkContainer>
                  <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => value.toggleMenu()}
                  />
                </LinkContainer>
              </MobNavHeader>
              <LinksContainer>
                <LinkContainer>
                  <MobNavLink activeClassName="active" to="/uppdrag">
                    Uppdrag
                  </MobNavLink>
                </LinkContainer>
                <LinkContainer>
                  <MobNavLink activeClassName="active" to="/event">
                    Event
                  </MobNavLink>
                </LinkContainer>
                <LinkContainer>
                  <MobNavLink activeClassName="active" to="/kommunikation">
                    Kommunikation
                  </MobNavLink>
                </LinkContainer>
                {/* 
                <LinkContainer>
                  <MobNavLink activeClassName="active" to="/om-creandia">
                    Om Creandia
                  </MobNavLink>
                </LinkContainer>
                */}

                <LinkContainer>
                  <MobNavLink activeClassName="active" to="/kontakt">
                    Kontakta oss
                  </MobNavLink>
                </LinkContainer>
                <LinkContainer>
                  <Line style={{ margin: '40px 0px' }} />
                </LinkContainer>

                <LinkContainer>
                  <div className="social-links">
                    <a
                      href="https://www.facebook.com/creandiaevent"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </a>
                    <a
                      href="https://www.instagram.com/creandiaevent"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Instagram
                    </a>
                    <a
                      href="https://www.linkedin.com/company/creandia-event-kommunikation"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      LinkedIn
                    </a>
                  </div>
                </LinkContainer>
              </LinksContainer>
            </Container>
          </MobileMenuContainer>
        )}
      </MenuConsumer>
    )
  }
}

export default MobileMenu
