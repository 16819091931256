import React, { Component } from 'react'

const MenuContext = React.createContext()

export class MenuProvider extends Component {
  state = {
    menuOpen: false,
  }
  menuToggle = this.handleMenuToggle.bind(this)
  handleMenuToggle() {
    this.setState({
      menuOpen: !this.state.menuOpen,
    })
  }
  render() {
    const { children } = this.props
    return (
      <MenuContext.Provider
        value={{
          ...this.state,
          toggleMenu: this.menuToggle,
        }}
      >
        {children}
      </MenuContext.Provider>
    )
  }
}

export const MenuConsumer = MenuContext.Consumer
