import styled from 'styled-components'
import { Link } from 'gatsby'

export const Line = styled.span`
  display: block;
  width: 150px;
  height: 2px;
  background: black;
  margin-bottom: 30px;
`
export const Btn = styled(Link)`
  color: black;
  font-weight: 500;
  text-decoration: none;
  border-radius: 99px;
  display: flex;
  justify-content: flex-start;
  flex-direction: ${props => (props.down ? 'column' : 'row')};
  align-items: ${props => (props.down ? 'flex-start' : 'center')};
  margin-top: 30px;
  outline: none;
  & > svg {
    margin-left: ${props => (props.down ? '0px' : '10px')};
    transform: ${props => (props.down ? 'rotateZ(90deg)' : '0')};
    transition: all 0.2s;
  }
  &:hover > svg {
    margin-left: 20px !important;
  }
`
export const Anchor = styled.a`
  cursor: pointer;
  color: black;
  font-weight: 500;
  text-decoration: none;
  border-radius: 99px;
  display: flex;
  justify-content: flex-start;
  flex-direction: ${props => (props.down ? 'column' : 'row')};
  align-items: ${props => (props.down ? 'flex-start' : 'center')};
  margin-top: 30px;
  outline: none;
  & > svg {
    margin-left: ${props => (props.down ? '0px' : '10px')};
    transform: ${props => (props.down ? 'rotateZ(90deg)' : '0')};
    transition: all 0.2s;
  }
  &:hover > svg {
    margin: ${props =>
      props.down ? '20px 0px -20px 0px' : '0px 0px 0px 20px'};
  }
`
export const BigBtn = styled(Link)`
  padding: 20px 40px;
  background: #000000;
  color: white;
  border-radius: 5px;
  text-align: center;
  outline: none;
  text-decoration: none;
  width: 100%;
`
